<template>
  <!-- Master Card -->
  <a-card
    class="card-credit header-solid h-full"
    style="background-image: url('images/info-card-8.png')"
  >
    <!-- <a-card class="card-credit header-solid h-full"> -->
    <template #title>
      <svg
        width="40"
        height="40"
        viewBox="0 0 24.00 24.00"
        xmlns="http://www.w3.org/2000/svg"
        fill="#ffffff"
        stroke="#ffffff"
        stroke-width="0.00024000000000000003"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke="#CCCCCC"
          stroke-width="1.248"
        ></g>
        <g id="SVGRepo_iconCarrier">
          <g>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path
              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-4H8V8h3V6h2v2h1a2.5 2.5 0 0 1 2 4 2.5 2.5 0 0 1-2 4h-1v2h-2v-2zm-1-3v1h4a.5.5 0 1 0 0-1h-4zm0-3v1h4a.5.5 0 1 0 0-1h-4z"
            ></path>
          </g>
        </g>
      </svg>
    </template>
    <div style="margin-left: 14px">
      <h2 class="m-1"><Balance /></h2>

      <div class="card-footer">
        <div class="mr-30">
          <p>Username</p>
          <h6>{{ getUsername }}</h6>
        </div>
        <div class="mr-30">
          <p>เวลาปัจจุบัน</p>
          <h6>{{ timestamp }}</h6>
        </div>
        <div class="card-footer-col col-logo ml-auto">
          <img src="images/logos/mastercard-logo.png" />
        </div>
      </div>
    </div>
  </a-card>
  <!-- / Master Card -->
</template>

<script>
import Balance from "@/views/components/wallet/Balance.vue";

export default {
  components: {
    Balance,
  },
  computed: {
    getUsername: function () {
      return JSON.parse(localStorage.getItem("userInfo")).displayName;
    },
    // displayTime: function () {
    //   return setInterval(this.refreshTime, 1000);
    // },
  },
  created() {
    setInterval(this.getNow, 1000);
  },
  data() {
    return { timestamp: "loading..." };
  },
  methods: {
    getNow: function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        (today.getHours().toString().length != 2 ? "0" : "") +
        today.getHours() +
        ":" +
        (today.getMinutes().toString().length != 2 ? "0" : "") +
        today.getMinutes() +
        ":" +
        (today.getSeconds().toString().length != 2 ? "0" : "") +
        today.getSeconds();
      const dateTime = time + " น.";
      this.timestamp = dateTime;
    },
  },
};
</script>
